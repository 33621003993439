import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/20/1.gif'
import webImg from '../../assets/blog/20/2.png'
import webImg2 from '../../assets/blog/20/3.png'
import mobileImg from '../../assets/blog/20/4.png'
import heroImg from '../../assets/blog/20/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Jak si v pohodě užít prosinec" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Jak si v pohodě užít prosinec" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Jak si v pohodě užít prosinec"
              className="object-cover w-full max-h-[600px] rounded-[20px]"
            />
          </div>
          <p>
            Prosinec je tu a s ním ta nejvíce kouzelná, ale zároveň i
            nejhektičtější doba roku. Chcete-li se vyhnout vánočnímu šílenství a
            užít si Mikuláše, Vánoce i Silvestra naplno, je třeba mít věci pod
            kontrolou. A všichni víme, že je toho opravdu hodně!
          </p>
          <p>
            Začneme Mikulášem, který je jen předehrou k vánočnímu maratonu. Pak
            jsou tu školní besídky, které vyžadují nejen přípravu kostýmů, ale i
            koordinaci s ostatními rodiči a pedagogy. A co teprve nákupy dárků!
            Každý rok se snažíme vymyslet něco originálního a osobního pro naše
            blízké, abychom viděli ten jejich šťastný výraz, když rozbalují
            dárky pod stromečkem.
          </p>
          <p>
            Ale jak to všechno stihnout, aniž bychom se zbláznili? Klíčem je
            organizace. A kdo je lepší organizátor než chytrý pomocník, který je
            vždy po ruce? Řeč je o mobilní a webové aplikaci Flagis, která se
            stane vaším osobním asistentem v této hektické době.
          </p>
          <p>
            Flagis vám pomůže zorganizovat všechny ty večírky, školní akce,
            nákupy dárků, vánoční pečení, úklid, ale i pohlídat pohádky a filmy,
            které si budete chtít užít se svými blízkými. S touto aplikací si
            můžete snadno nastavit připomenutí pro každou důležitou událost, ať
            už je to termín odeslání vánočních přání, nákup ingrediencí na
            vánoční cukroví nebo deadline pro nákup dárků, abyste se vyhnuli
            poslednímu předvánočnímu bláznění v obchodech.
          </p>
          <p>
            Co víc, Flagis je jako chytrý poznámkový blok, kam si můžete zapsat
            všechny ty nápady na dárky, které vás napadnou v nejneočekávanějších
            chvílích. Představte si, že jste na cestě do práce a náhle vás to
            napadne – &quot;To by bylo skvělé pro mamku!&quot; Otevřete
            aplikaci, zadáte nápad a můžete být klidní, že na něj nezapomenete.
          </p>
          <p>
            A co recepty na vánoční pečení? Mějte je všechny pohodlně uložené na
            jednom místě. Můžete si je také posílat s&nbsp;rodinou nebo přáteli.
            Už žádné zdlouhavé hledání receptů v kuchařkách, e-mailech nebo na
            papíře založeném někde v&nbsp;sešitě s recepty.
          </p>
          <p>
            Nechte tedy stres z organizace prosincových radovánek na aplikaci
            Flagis a užijte si klidný, radostný a především organizovaný závěr
            roku. Kdo by chtěl trávit Vánoce v obchodním centru místo toho, aby
            popíjel svařené víno a sledoval, jak sníh jemně padá za oknem? Tak
            veselé Vánoce a šťastný nový rok!
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={webImg} target="_blank" rel="noreferrer">
              <img
                src={webImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={webImg2} target="_blank" rel="noreferrer">
              <img
                src={webImg2}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={mobileImg} target="_blank" rel="noreferrer">
              <img
                src={mobileImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
